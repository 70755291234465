@import './../../constants/colors.scss';

.billboard {
	background: black;
	background-repeat: no-repeat;
	background-position: -500px center;
	color: white;

	//TODO: potentially handle resize/repositioning of background with state and hooks.
	@media (min-width: 768px) {
		display: flex;
		min-height: 666px;
		&::before {
			background: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0.75),
				rgba(0, 0, 0, 0),
				rgba(255, 143, 0, 0),
				rgba(255, 143, 0, 0)
			);
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
		}
	}
	@media (min-width: 900px) {
		background-position: -400px center;
	}
	@media (min-width: 1024px) {
		background-position: -300px center;
	}
	@media (min-width: 1120px) {
		background-position: -200px center;
	}
	@media (min-width: 1200px) {
		background-position: -100px center;
	}
	@media (min-width: 1280px) {
		background-position: center;
	}

	&-header {
		h1 {
			font-weight: 900;
			font-size: 40px;
			@media (min-width: 768px) {
				font-size: 60px;
				margin-top: 10px;
			}
			@media (min-width: 900px) {
				font-size: 80px;
				margin-top: 10px;
			}
			@media (min-width: 1024px) {
				font-size: 100px;
			}
		}
		&__content {
			padding: 20px;
			position: absolute;
			top: 0;
			z-index: 2;
			@media (min-width: 768px) {
				position: relative;
			}
		}
		&__image {
			position: relative;

			img {
				width: 100%;
				height: auto;
			}

			&::before {
				background: linear-gradient(
					to right bottom,
					rgba(0, 0, 0, 0.75),
					rgba(0, 0, 0, 0),
					rgba(0, 0, 0, 0)
				);
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
			}
			@media (min-width: 370px) {
				max-height: 470px;
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			&--mobile {
				display: flex;
				@media (min-width: 480px) {
					display: none;
				}
			}
			&--tablet {
				display: none;
				@media (min-width: 480px) {
					display: flex;
				}
				@media (min-width: 768px) {
					display: none;
				}
			}
		}
	}

	&-description {
		padding: 20px;
		align-self: center;
		margin-left: auto;
		background: $brown;
		z-index: 2;

		@media (min-width: 768px) {
			max-width: 340px;
			background: none;
		}
		@media (min-width: 1200px) {
			max-width: 440px;
		}

		h2 {
			font-size: 24px;
			@media (min-width: 768px) {
				font-size: 28px;
			}
		}
		p {
			font-size: 18px;
		}
		a {
			color: white;
			// TODO: remove this once link's are established.
			margin-right: 20px;
			&:hover {
				text-decoration: none;
			}
		}
	}
}
