@import '~normalize.css';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800&display=swap');

@import './constants/colors.scss';

body {
	font-family: 'Montserrat', sans-serif;
	background-color: $brown;
}
